
import {
  Component, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import Swiper, { SwiperOptions } from 'swiper'
import BasePicture from './base/BasePicture.vue'
import BaseLink from './base/BaseLink.vue'
import BaseIconButton from './base/BaseIconButton.vue'
import 'swiper/swiper-bundle.css'
import { TReferenceElement } from '../shared/general/types/TReferenceElement'

@Component({
  name: 'References',
  components: {
    BasePicture,
    BaseLink,
    BaseIconButton,
    BaseHeadline: () => import('./base/BaseHeadline.vue'),
  },
})
export default class References extends Vue {
  @Prop() headline ?: string

  @Prop({ default: () => [] }) references! : TReferenceElement[]

  private isOverflowing : boolean = false

  private isEnd : boolean = false

  private isBeginning : boolean = false

  private swiper ?: Swiper

  private slidesWrapper ?: HTMLElement

  private firstSlide ?: HTMLElement

  private lastSlide ?: HTMLElement

  mounted () {
    this.initSwiper()

    window.addEventListener('resize', () => {
      this.checkOverflow()
    })
  }

  private async initSwiper () : Promise<void> {
    this.swiper = new Swiper(this.$refs.swiper as HTMLElement, {
      horizontal: true,
      slidesPerView: 'auto',
      spaceBetween: 30,
      loop: false,
      centeredSlides: true,
      centeredSlidesBounds: true,
      allowSlidePrev: true,
      allowSlideNext: true,
    } as SwiperOptions)

    this.swiper.on('slideChangeTransitionEnd', this.checkOverflow)

    this.slidesWrapper = this.$el?.querySelector('.swiper-wrapper') as HTMLElement
    this.firstSlide = this.$el?.querySelector('.swiper-wrapper > :first-child') as HTMLElement
    this.lastSlide = this.$el?.querySelector('.swiper-wrapper > :last-child') as HTMLElement

    this.checkOverflow()
  }

  private checkIsOverflowingLeft () : boolean {
    if (!this.slidesWrapper || !this.firstSlide) return false

    const { parentElement } = this.slidesWrapper
    return !!parentElement
     && this.firstSlide.getBoundingClientRect().left < parentElement.getBoundingClientRect().left - 1
  }

  private checkIsOverflowingRight () : boolean {
    if (!this.slidesWrapper || !this.lastSlide) return false

    const { parentElement } = this.slidesWrapper
    return !!parentElement
      && this.lastSlide.getBoundingClientRect().right > parentElement.getBoundingClientRect().right + 1
  }

  private slide (direction : string) : void {
    this.swiper?.[direction]()
    this.checkOverflow()
  }

  private checkOverflow () : void {
    this.isOverflowing = this.checkIsOverflowingLeft() || this.checkIsOverflowingRight()
    this.isBeginning = !!this.swiper?.isBeginning
    this.isEnd = !!this.swiper?.isEnd
  }

  @Watch('isOverflowing')
  onOverflowingChange (isOverflowing : boolean) : void {
    if (isOverflowing) {
      this.swiper?.enable()
      setTimeout(() => {
        this.swiper?.slideTo(0)
        this.checkOverflow()
      }, 200)
    } else {
      this.swiper?.disable()
    }
  }
}
